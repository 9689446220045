import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Container } from '../components/Scaffold'
import Showreel from '../components/Showreel'
import { systemFontStack } from '../utils/typography'

const TextSection = styled.div`
  margin-top: 80px;
`

const Heading = styled.h2`
  font-size: 28px;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin-bottom: 28px;
`

const SubHeading = styled.h3`
  color: #737373;
  font-family: ${systemFontStack.join(',')};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 14px;
  text-transform: uppercase;
`

const ShowreelContainer = styled.div`
  margin-bottom: 64px;
`

export default () => {
  return (
    <Layout>
      <Container maxWidth={640}>
        <ShowreelContainer>
          <Showreel />
        </ShowreelContainer>
        <h1>Hey! I'm Faith, a freelance <Link to="/services/audio">radio producer</Link> based in Manchester.</h1>
      </Container>
      <Container maxWidth={640}>
        <TextSection>
          <SubHeading>Introduction</SubHeading>
          <Heading>A Manchester based Audio and Social Media Producer with a passion for sound.</Heading>
          <p>I love the little details of the way things sound, the little nuances and effects that merge together to create amazing stories and pictures in the mind, I'm constantly fascinated with a medium that gets right between your ears. I hope to create audio that makes you re-think how sound can be used to tell tales.</p>
          <p>I am a graduate of Salford University where I left with First class honours in TV & Radio Production. Those three years saw me try many different forms of media, but the one that stuck with me is Radio and Podcasting, I love everything from feature making to soundscapes.</p>
          <p>Since graduating I have ventured into the world of freelancing which has allowed me to develop skills in Social Media production and further Podcast and Documentary production.</p>
        </TextSection>
      </Container>
    </Layout>
  )
}
