import React from 'react'
import styled from 'styled-components'
import audio from '../../static/faith-howley-audio-showreel.mp3'

const Audio = styled.audio`
    display: block;
    width: 100%;
    outline: none;
`

const Showreel = () => <Audio src={audio} title="Audio Showreel" controls />

export default Showreel